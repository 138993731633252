.MuiDialog-paperScrollPaper {
  max-height: calc(100% - 90px);
  margin-top: 90px;
}

.MuiTableSortLabel-root:hover .MuiTableSortLabel-icon {
  opacity: 0.3;
}

.recharts-tooltip-wrapper {
  z-index: 1000;
}